/* eslint-disable jsx-a11y/no-distracting-elements */
import { CircleIndicator } from 'components/CircleIndicator/CircleIndicator';
import styles from './App.module.scss';
import image from 'images/image.jpg';
import { ReactComponent as Github } from 'images/github.svg';
import { ReactComponent as LinkedIn } from 'images/linkedin.svg';
import { ReactComponent as Email } from 'images/email.svg';
import { useState } from 'react';


function App() {
  const [show, setShow] = useState(false);

  return (
    <>
      <CircleIndicator />
      <section>
        <header>
          <h1 className={styles.title1}>
            Hello, Asisit
          </h1>
        </header>
        <article className={styles.grid6x1}>
          <div className={styles.box2}>
            <img src={image} alt="It's me" />
          </div>
          <div className={styles.box4}>
            <p>
              consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </p>
          </div>
        </article>
        <article className={styles.grid6x1}>
          <div className={styles.box6}>
            <h2>My title</h2>
          </div>
          <div className={styles.box4}>
            <p>
              consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </p>
          </div>
          <div className={styles.box2}>
            <ul>
              <li>something</li>
              <li>something else</li>
              <li>and another one</li>
            </ul>
          </div>
        </article>
        <figure className={show ? styles.no : styles.hidden}>
          <p>No</p>
        </figure>
        <article>
          <div className={styles.links}>
            <a href="https://github.com/louis197" target="_blank" rel="noreferrer">
              <Github />
            </a>
            <div onClick={() => setShow((prevShow) => !prevShow)}>
              <LinkedIn />
            </div>
            <a href="mailto:no-reply@asisit.ch">
              <Email />
            </a>
          </div>
        </article>
      </section>
    </>
  );
}

export default App;
